import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "2019 年、今年も開発本部キックオフ合宿に行ってきました！@河口湖",
  "date": "2019-02-08T04:54:27.000Z",
  "slug": "entry/2019/02/08/135427",
  "tags": ["medley"],
  "hero": "./2019_02_08.png",
  "heroAlt": "2019 合宿"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは、開発本部の日下です。`}</p>
    <p>{`インターンを経て新卒としてメドレーに入り、エンジニアとしては 2 年目となりました。`}</p>
    <p>{`現在は医療介護の求人サイト「`}<a parentName="p" {...{
        "href": "https://job-medley.com/"
      }}>{`ジョブメドレー`}</a>{`」の開発をしております。`}</p>
    <p>{`先日、恒例となった年始の開発本部キックオフ合宿を河口湖で行ってきましたので、詳細をレポートします。`}{` `}</p>
    <h1>{`キックオフを年始に行う意義`}</h1>
    <p>{`メドレーの開発本部では毎年年始に開発本部全体でキックオフ合宿を行っています。`}</p>
    <p>{`合宿を年始に実施する意義は`}<a parentName="p" {...{
        "href": "/entry/2018/02/09/130000"
      }}>{`昨年もまとめ`}</a>{`ましたが、再掲すると次の二つになります。`}</p>
    <h2>{`親睦を深める`}</h2>
    <p>{`一つ目はチームビルディングにあります。`}</p>
    <p>{`合宿ではアクティビティやバーベキュー、キックオフプレゼンなど、みんなで一つの場所で同じ時間を共有するイベントを行っています。`}</p>
    <p>{`同じ時間を共有することで、より一体感をもち、連携の取りやすい関係性を醸成することを目的としています。`}</p>
    <p>{`実際、より長い時間を共有することで、普段知ることのできない仲間の一面を見ることができるなど、よりお互いの理解を深める事ができる時間が過ごせます。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190208/20190208133854.jpg",
        "alt": "f:id:medley_inc:20190208133854j:plain",
        "title": "f:id:medley_inc:20190208133854j:plain"
      }}></img></p>
    <h2>{`今年の方向性を共有する`}</h2>
    <p>{`二つ目は今年一年の開発本部としての方向性の共有にあります。`}</p>
    <p>{`普段開発していると、全体のことを考える時間はなかなか取りづらいかと思います。`}</p>
    <p>{`そこでメドレーでは普段の業務から一旦離れた場所で、開発部全体としてどういった動き方をしていくのかという認識を作る機会を作っています。`}</p>
    <p>{`これが合宿を年始に行う理由にもなっています。`}</p>
    <h1>{`合宿先・アクティビティの紹介`}</h1>
    <h2>{`合宿先の紹介`}</h2>
    <p>{`合宿先は昨年と同じ`}<a parentName="p" {...{
        "href": "https://www.c-ban.com/"
      }}>{`河口湖カントリーコテージ ban`}</a>{`さんにお世話になることにしました。`}</p>
    <p>{`昨年よりメンバーが増えたため、昨年より少し大きなコテージを借りての実施となりました。`}</p>
    <p>{`コテージも夜景も美しく、とても満足のいく環境でした。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190208/20190208133927.jpg",
        "alt": "f:id:medley_inc:20190208133927j:plain",
        "title": "f:id:medley_inc:20190208133927j:plain"
      }}></img></p>
    <h2>{`ワカサギ釣り`}</h2>
    <p>{`合宿ではリフレッシュを兼ねたアクティビティを行っており、今年はワカサギ釣りでした。`}</p>
    <p>{`ワカサギ釣りと言われると氷に穴を開けて糸を垂らすイメージが強く、極寒の中で行うのではと思われる方もいるかもしれません。`}</p>
    <p>{`今回お世話になった`}<a parentName="p" {...{
        "href": "https://www.mfi.or.jp/bass/wakasagi.html"
      }}>{`マリンハウス momo さん`}</a>{`のプランでは、提供されたドーム型の船の中で釣ることができたため非常に快適でした。`}</p>
    <p>{`(`}<a parentName="p" {...{
        "href": "https://info.medley.jp/entry/2019/01/25/194253"
      }}>{`先日の週間メドレー`}</a>{`で着ていたライフジャケットは、このドーム型の船に乗るためのアイテムでした。)`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190208/20190208134010.jpg",
        "alt": "f:id:medley_inc:20190208134010j:plain",
        "title": "f:id:medley_inc:20190208134010j:plain"
      }}></img></p>
    <p>{`ワカサギは群れで移動する魚らしく、ちょうど群れが来たときはまさにフィーバータイム。釣り糸を垂れるとすぐにかかるので、とても楽しめました。`}</p>
    <figure {...{
      "className": "figure-image figure-image-fotolife mceNonEditable",
      "title": "5 匹一気に釣り上げ喜ぶ CTO"
    }}>
      <img parentName="figure" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190208/20190208134024.jpg ",
        "alt": "f:id:medley_inc:20190208134024j:plain"
      }}></img>
      <figcaption parentName="figure">{`5 匹一気に釣り上げ喜ぶ CTO`}</figcaption>
    </figure>
    <p>{`釣りが得意なメンバーはバケツ満杯に釣れるなど、釣果は上々。当初釣れた数を数える予定でしたが、あまりにも多すぎたため途中で数えるのをやめてしましました。`}</p>
    <figure {...{
      "className": "figure-image figure-image-fotolife mceNonEditable",
      "title": "袋いっぱいのワカサギ。多すぎて 2 袋に分けたうちの一つです。"
    }}>
      <img parentName="figure" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190208/20190208134051.jpg ",
        "alt": "f:id:medley_inc:20190208134051j:plain"
      }}></img>
      <figcaption parentName="figure">{`袋いっぱいのワカサギ。多すぎて 2 袋に分けたうちの一つです。`}</figcaption>
    </figure>
    <p>{`釣れた魚は合宿先に持ち帰り、唐揚げにしたり串焼きにしたりと、食事面でも楽しめるとても良いアクティビティでした。`}</p>
    <figure {...{
      "className": "figure-image figure-image-fotolife mceNonEditable",
      "title": "一気に焼くために串に刺されるワカサギ"
    }}>
      <img parentName="figure" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190208/20190208134120.jpg ",
        "alt": "f:id:medley_inc:20190208134120j:plain"
      }}></img>
      <figcaption parentName="figure">{`一気に焼くために串に刺されるワカサギ`}</figcaption>
    </figure>
    <p>{`くさみもなく、とても美味しかったです。`}</p>
    <h1>{`2019 年の開発本部キックオフ！`}</h1>
    <p>{`アクティビティのあとはコテージに移り、お風呂やバーベキューの準備をしながらお酒を少し入れたタイミングで、2019 年キックオフプレゼンを CTO ・平山が行いました。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190208/20190208134432.jpg",
        "alt": "f:id:medley_inc:20190208134432j:plain",
        "title": "f:id:medley_inc:20190208134432j:plain"
      }}></img></p>
    <p>{`まずは昨年の振り返りから。昨年も各プロダクト大きな変化がありました。`}</p>
    <p>{`JobMedley はユーザ数が順調に伸び、`}<a parentName="p" {...{
        "href": "https://job-medley.com/release/44/"
      }}>{`テレビで CM をするほどの規模に成長`}</a>{`、`}<a parentName="p" {...{
        "href": "https://clinics.medley.life/karte%E2%80%8B"
      }}>{`CLINICS カルテ`}</a>{`のリリース、`}<a parentName="p" {...{
        "href": "https://www.kaigonohonne.com/"
      }}>{`介護のほんね`}</a>{`のリニューアル、`}<a parentName="p" {...{
        "href": "https://medley.life/"
      }}>{`MEDLEY`}</a>{`は医師向け機能の強化をするなど、プロダクトを大きく成長させました。`}</p>
    <p>{`また会社としては、医療ヘルスケア分野における技術のオープン化、および情報活用を推進すること目的とした`}<a parentName="p" {...{
        "href": "https://drive.medley.jp"
      }}>{`MEDLEY DRIVE`}</a>{`という大型プロジェクトを開始しました。`}</p>
    <p>{`他にもセキュリティ強化を目的として`}<a parentName="p" {...{
        "href": "/entry/2019/02/01/172457"
      }}>{`ISMS と ISMS クラウドを取得`}</a>{`するなど、攻める一方で守りの面も強化された一年でした。`}</p>
    <p>{`次に開発本部の 2019 年について。`}</p>
    <p>{`目指すべき未来を示しつつ、なぜ？どうやって？といった基本的な考え方を共有することで、メンバーの中に納得感ができたキックオフになりました。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190208/20190208134505.jpg",
        "alt": "f:id:medley_inc:20190208134505j:plain",
        "title": "f:id:medley_inc:20190208134505j:plain"
      }}></img></p>
    <p>{`個人的には昨年から関わるプロダクトが増えたため、これまで肌で感じてきたプロダクトの成長を振り返ることができました。`}</p>
    <p>{`また今年はどういったことができるか考える良い時間になりました。`}</p>
    <p>{`平山のプロダクト・事業・会社に対する熱い思いは`}<a parentName="p" {...{
        "href": "https://toppa.medley.jp/"
      }}>{`メドレー平山の中央突破`}</a>{`にも詳細に書かれていますので、ぜひご覧ください。`}</p>
    <h1>{`バーベキュー`}</h1>
    <p>{`恒例のキックオフ後のバーベキューもみんなでワイワイしながらの実施となりました。`}</p>
    <p>{`今年は人数が昨年同月比 1.5 倍ほどになったのもあり、昨年より賑やかな会となりました。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190208/20190208134558.jpg",
        "alt": "f:id:medley_inc:20190208134558j:plain",
        "title": "f:id:medley_inc:20190208134558j:plain"
      }}></img></p>
    <figure {...{
      "className": "figure-image figure-image-fotolife mceNonEditable",
      "title": "今年は自前の燻製器を運び込んだ猛者も"
    }}>
      <img parentName="figure" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190208/20190208134604.jpg ",
        "alt": "f:id:medley_inc:20190208134604j:plain"
      }}></img>
      <figcaption parentName="figure">{`今年は自前の燻製器を運び込んだ猛者も`}</figcaption>
    </figure>
    <figure {...{
      "className": "figure-image figure-image-fotolife mceNonEditable",
      "title": "恒例となっているバーベキュー"
    }}>
      <img parentName="figure" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190208/20190208134610.jpg ",
        "alt": "f:id:medley_inc:20190208134610j:plain"
      }}></img>
      <figcaption parentName="figure">{`恒例となっているバーベキュー`}</figcaption>
    </figure>
    <p>{`食べて飲んで寛いで、プロダクトや組織への思いなどお酒を交えつつ談笑し、夜遅くまで楽しい時間を過ごしました。`}</p>
    <h1>{`まとめと感想`}</h1>
    <p>{`メドレー開発本部合宿の様子はいかがだっだでしょうか？少しでもその様子が伝わっていれば幸いです。`}</p>
    <p>{`今年の合宿も、メンバー同士が物理的に近い状態で同じ体験をする時間を通じ、相互理解を深めたり共通認識を持つことのできる良い機会となりました。`}</p>
    <p>{`昨年よりいろいろな人が増えたおかげで、バラエティの富んだ時間を過ごすことができたのは良い発見でした。`}</p>
    <p>{`また人が増えたものの雰囲気としては昨年と変わらず進められたことは、組織としてのメドレーらしさのようなものが醸成されている証ではないかと感じています。`}</p>
    <p>{`個人的にもプロダクト的にも昨年は一昨年に比べて変化の激しく楽しい年だったように思います。`}</p>
    <p>{`今年はより変化のある一年になりそうということもわかり、今から期待に胸を膨らませています。`}</p>
    <p>{`改めてこういった時間を開発本部としてとるタイミングがあるのは良いことだと、再度認識することのできた時間となりました。`}</p>
    <h1>{`最後に`}</h1>
    <p>{`最後に、今回の合宿の企画運営を行って頂いた新居さん、忙しい中本当にありがとうございました！`}</p>
    <figure {...{
      "className": "figure-image figure-image-fotolife mceNonEditable",
      "title": "また来年も合宿しましょう！"
    }}>
      <img parentName="figure" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190208/20190208134755.jpg",
        "alt": "f:id:medley_inc:20190208134755j:plain"
      }}></img>
      <figcaption parentName="figure">{`また来年も合宿しましょう！`}</figcaption>
    </figure>
    <p>{`メドレーでは、このようなチームで新しい医療体験を提供するプロダクトを一緒に創るディレクターやエンジニア、デザイナーを募集しています。`}</p>
    <p>{`ご興味ある方は、お気軽に話を聞きにお越しください！！`}</p>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="募集職種 | 株式会社メドレー" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Fwww.medley.jp%2Fjobs%2F" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.medley.jp/jobs/">www.medley.jp</a></cite>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="CREATOR'S STORY | 株式会社メドレー" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Fwww.medley.jp%2Fteam%2Fcreator-story.html" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.medley.jp/team/creator-story.html">www.medley.jp</a></cite>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      